$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offsetDefault = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight(),
                offset = $(this).data("animation-offset") ?? offsetDefault;

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();

    });

    fadeInElements();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(".js-navbutton, .js-nav, .js-header").toggleClass("active");
        $("body").toggleClass("freeze");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mapSlider
    // *
    // *
    var $mapPin = $(".js-mapslider-pin");

    // position pins
    $mapPin.each(function() {
        var xPos = $(this).data("position-x"),
            yPos = $(this).data("position-y");
        $(this).css("left", xPos + '%');
        $(this).css("top", yPos + '%');
    })

    // zoom map and toggle active class on pin
    $mapPin.click(function() {
        $(this).addClass("active").siblings().removeClass("active");
        var xZoom = $(this).data("position-x"),
            yZoom = $(this).data("position-y"),
            $map = $(this).parents(".js-mapslider-map"),
            pinIndex = $(this).index();
        $map.addClass("zoomed").css("transform-origin", xZoom + '%' + yZoom + '%');

        $(this).parents(".js-mapslider-wrapper").find(".js-mapslider").slick("slickGoTo", pinIndex + 1);
    })

    // remove zoom on map and activ class on pin
    $(".js-mapslider-map .mapSlider__graphic").click(function() {
        $(this).parents(".js-mapslider-map").removeClass("zoomed");
        $(".js-mapslider-pin").removeClass("active zoomed");
    })

    var $mapSlider = $(".js-mapslider");

    if ($mapSlider.length) {
        $mapSlider.each(function () {
            var $mapSliderWrapper = $(this).parents(".js-mapslider-wrapper");

            $(this).slick({
                fade: false,
                dots: false,
                infinite: false,
                arrows: true,
                variableWidth: true,
                adaptiveHeight: true,
                prevArrow: $mapSliderWrapper.find(".js-mapslider-prev"),
                nextArrow: $mapSliderWrapper.find(".js-mapslider-next"),
                autoplay: false,
                speed: 800,
                rows: 0,
             });

            // set total
            var total = $(this).find(".slick-slide:not(.slick-cloned)").length - 1;
            $mapSliderWrapper.find(".js-mapslider-total").html(total);

            // set current number
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                if (nextSlide == 0) {
                    $mapSliderWrapper.find(".js-mapslider-map .mapSlider__graphic").trigger("click");
                } else {
                    $mapSliderWrapper.find(".js-mapslider-pin").eq(nextSlide - 1).trigger("click");
                }
            });
        })
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * carouselCard
    // *
    // *
    var $carouselCard = $(".js-carouselcard");

    if ($carouselCard.length) {
        $carouselCard.each(function () {
            $(this).carousel({
                // center spacing default 0
                shift: -200,
                // perspective zoom defualt -100
                dist: -200,
                numVisible: 3,
                duration: 150
            });

            // pagination
            $(this).parents(".js-carouselcard-wrapper").find(".js-carouselcard-prev").click(function() {
                $carouselCard.carousel("prev");
            });
            $(this).parents(".js-carouselcard-wrapper").find(".js-carouselcard-next").click(function() {
                $carouselCard.carousel("next");
            });

            // start slider randomly
            var totalSlides = $(this).find(".carousel-item").length,
                randomSlideNumber = Math.floor(Math.random() * totalSlides) + 1

            $(this).carousel("next", randomSlideNumber);
        })
    };




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * videoGallery
    // *
    // *
    // add class to hide button
    $(".js-videogallery-video").click(function() {
        var $video = $(this);

        $video[0].play();
        $video.addClass("active");

        setTimeout(function() {
            $video.prop("controls",true);
        }, 10);
    });

    // jump to timestamp and play
    $("[data-videogallery-timestamp]").click(function() {
        var $video = $(this).parents(".js-videogallery").find(".js-videogallery-video");

        // $video.play();
        // $video.pause();
        $video.addClass("active");
        $video[0].currentTime = $(this).data("videogallery-timestamp");
        $video[0].play();

        setTimeout(function() {
            $video.prop("controls",true);
        }, 10);
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserproduct
    // *
    // *
    var $teaserProduct = $(".js-teaserproduct");

    $teaserProduct.each(function(){
        gsap.to($(this), {
            scrollTrigger: {
                trigger: $(this),
                toggleClass: "active",
                scrub: true,
                markers: false,
                start: "top-=30 center",
                end: "bottom+=55 center"
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ticker
    // *
    // *
    // trigger ticker by scroll
    var $ticker = $(".js-ticker");

    $ticker.each(function(){
        var offset = 300 - ($(this).index() * 150);

        gsap.to($(this), {
            scrollTrigger: {
                trigger: $(this),
                toggleClass: "active",
                scrub: true,
                markers: false,
                start: "top-=" + offset + " 50%",
                end: "bottom+=100 0%"
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * herovideo scrub
    // *
    // *
    var $heroVideo = $(".js-herovideo");

    if ($heroVideo.length) {
        $heroVideo.each(function() {
            // ios fix
            if(window.matchMedia("(pointer: coarse)").matches) {
                $heroVideo.get(0).play();
                $heroVideo.get(0).pause();
            }

            var videoDuration = $(this).data("video-duration");

            gsap.fromTo($(this), {
                currentTime: 0
            }, {
                currentTime: $(this).duration || videoDuration,
                scrollTrigger: {
                    trigger: $(this),
                    start: "top+=200 bottom",
                    end: "bottom top",
                    scrub: true,
                    markers: false
                }
            });
        })
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroImage
    // *
    // *
    var $heroImageHeadline = $(".js-heroimage-headline"),
        $heroImage = $(".js-heroimage");

    gsap.to($heroImageHeadline, {
        y: '35vh',
        scrollTrigger: {
            trigger: $heroImage,
            scrub: true,
            markers: false,
            start: "top-=65 top",
            end: "bottom center"
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * megaVideo scrub
    // *
    // *
    var $megaVideo = $(".js-megavideo");

    if ($megaVideo.length) {
        $megaVideo.each(function() {
            var videoDuration = $(this).data("video-duration");

            gsap.fromTo($(this), {
                currentTime: 0
            }, {
                currentTime: $(this).duration || videoDuration,
                scrollTrigger: {
                    trigger: $(this),
                    scrub: true,
                    pin: true,
                    markers: false,
                    start: "top top",
                    end: "bottom+=2000 top"
                }
            });
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * megaAnimation
    // *
    // *
    var $maCanvas = $(".js-megaanimation-canvaswrapper");

    if ($maCanvas.length) {
        const timeline = gsap.timeline();
        // var canvasHeight = ($(window).height() * 455) * 0.018;
        // console.log(canvasHeight);

        gsap.timeline({
            onUpdate: render,
            scrollTrigger: {
                trigger: $maCanvas,
                pin: true,
                //pinSpacing: true,
                scrub: true,
                markers: false,
                end: "+=9000",
                onToggle: function(self){}
            }
        })
        .to($maCanvas, {})

        .to('.js-megaanimation-text-1', {
            autoAlpha: 1,
            y: '-30%',
            duration: .2
        }, "-=3.5")
        .to('.js-megaanimation-text-1', {
            autoAlpha: 0,
            y: '0',
            duration: .1
        }, ">=0.3")

        .to('.js-megaanimation-text-2', {
            autoAlpha: 1,
            duration: .2
        }, ">=0.15")
        .to('.js-megaanimation-text-2', {
            autoAlpha: 0,
            duration: .1
        }, ">=0.1")

        .to('.js-megaanimation-text-3', {
            autoAlpha: 1,
            duration: .2
        }, ">=0.25")
        .to('.js-megaanimation-text-3', {
            autoAlpha: 0,
            duration: .1
        }, ">=.5")

        .to('.js-megaanimation-text-4', {
            autoAlpha: 1,
            duration: .2
        }, ">=0.5")
        .to('.js-megaanimation-text-4', {
            autoAlpha: 0,
            duration: .1
        }, ">=0.3")

        const html = document.documentElement;
        const canvas = document.getElementById("mega-animation");
        const context = canvas.getContext("2d");
        const frameCount = 455;
        const speed = 0.05;
        const currentFrame = index => (
            `assets/templates/web/img/megaanimation/ma-${index.toString()}.jpg`
        );

        const img = new Image()
            img.src = currentFrame(1);
            canvas.width=1920;
            canvas.height=1080;
            img.onload=function(){
                context.drawImage(img, 0, 0);
            }

        const updateImage = index => {
            img.src = currentFrame(index);
            context.drawImage(img, 0, 0);
        }

        function render (){
            var scrollTop = html.scrollTop,
                scrollOffset = $('.js-megaanimation').offset().top,
                scrollDistance = Math.ceil(scrollTop - scrollOffset),
                frameIndex =  Math.min((frameCount - 1), Math.max(0, Math.ceil(scrollDistance * speed)));

            if (frameIndex <= 35) {
                frameIndex = 35;
            }

            requestAnimationFrame(() => updateImage(frameIndex + 1))
        };
    }
});



$(window).on("load", function(){
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * preload mega animation
    // *
    // *
    function preloadImages(countStart = 1, countEnd = 455) {
        const currentFrame = index => (
            `assets/templates/web/img/megaanimation/ma-${index.toString()}.jpg`
        );

        for (let i = countStart; i < countEnd; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            img.onload = function() {
                //console.log('loaded: ' + currentFrame(i));
            }
            img.onerror = function(err) {
                console.log('error loading mega-animation image: ' + currentFrame(i));
            }
        }
    };

    if ($(window).width() >= 1001) {
        preloadImages(35, 100);

        setTimeout(function(){
            preloadImages(101, 200);
        }, 500);

        setTimeout(function(){
            preloadImages(201, 350);
        }, 1000);

        setTimeout(function(){
            preloadImages(351, 455);
        }, 2000);
    }

});

$(window).on("load scroll", function(){

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parallax
    // *
    // *
    $("[data-parallax-speed]").each(function(){
        var scrollDistance = $(window).scrollTop(),
            elementParallaxOffset = parseInt($(this).css('transform').split(',')[5] ?? 0),
            elementDistance = Math.round($(this).offset().top - elementParallaxOffset),
            windowHeight = $(window).height(),
            parallaxValue = Math.round(((scrollDistance - elementDistance) + (windowHeight / 2))),
            parallaxValue = (parallaxValue * -1),
            parallaxSpeed = $(this).data("parallax-speed"),
            parallaxValue = parallaxValue * parallaxSpeed;

        // avoid negative scrolling

        $(this).css("transform", 'translateY(' + parallaxValue + 'px)');
        $(this).data("parallax-original", parallaxValue);
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * show notifications
    // *
    // *
    $(".js-application:nth-child(1)").fadeIn().addClass("active");

    if($(".js-application-wrapper.animateIn--active").length) {


        var i = 2;
        setInterval(function(){
            $(".js-application:nth-child(" + i + ")").fadeIn().addClass("active");
            i++;
            if($(".js-application.active").length == 4) {
                $(".js-application.active").eq(0).hide();
            }
        }, 1500);


    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * jumpNav
    // *
    // *
    var $sections = $("[data-nav-section]"),
        $jumpNav = $(".js-jumpnav"),
        navHeight = $(".js-header").outerHeight() + 40,
        triggerHeight = $(".js-jumpnav-heighttrigger").outerHeight() - 300,
        cur_pos = $(this).scrollTop(),
        footerPosition = $(".js-footer").position(),
        footerPositionTop = footerPosition.top - 600; //offset

    // trigger jumpNav when area is scrolled
    if (cur_pos >= triggerHeight && cur_pos < footerPositionTop) {
        $jumpNav.addClass("active");
    } else {
        $jumpNav.removeClass("active");
    }

    $sections.each(function() {
        var top = $(this).offset().top - navHeight,
            bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
            $jumpNav.find('li').removeClass('active');
            // $sections.removeClass('active');
            // $(this).addClass('active');
            // $jumpNav.find('a[href="#' + $(this).data('nav-section') + '"]').parent('li').addClass('active');
            $jumpNav.find('a[href="#'+$(this).attr('id')+'"]').parent('li').addClass('active');
        }
    });


});


